<!--
 * @Descripttion:
 * @version:
 * @Author: wangxiaole
 * @Date: 2020-02-19 14:00:54
 * @LastEditors: wangxiaole
 * @LastEditTime: 2020-02-26 16:44:41
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
